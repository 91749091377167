<script setup>
import {computed, onMounted, ref} from "vue";

import PaginationComponent from '../Components/PaginationComponent.vue'
import {useKnowledgeBaseItemsApi} from "../Composables/useKnowledgeBaseItemsApi";
import IconMagnifyingGlass from "../Icons/IconMagnifyingGlass.vue";
import IconClose from "../Icons/IconClose.vue";

let props = defineProps({
  knowledgeBaseItems: Object,
  knowledgeBaseTags: Object,
  knowledgeBaseCategories: Object,
});

const search = ref();
const categoriesDropdownIsOpen = ref();
const tagsDropdownIsOpen = ref();
const activeTagFilters = ref([]);
const selectedKnowledgeBaseCategory = ref();
const searchInput = ref(null);

const filteredKnowledgeBaseItems = computed(() => {
  let tempItems = props.knowledgeBaseItems;

  if (selectedKnowledgeBaseCategory.value === undefined) selectedKnowledgeBaseCategory.value = 'all'

  if (selectedKnowledgeBaseCategory.value) {
    if (selectedKnowledgeBaseCategory.value !== 'all') {
      tempItems = tempItems.filter((knowledgeBaseItem) => {
        return knowledgeBaseItem.categories.indexOf(selectedKnowledgeBaseCategory.value) !== -1;
      })
    }
  }

  if (activeTagFilters.value.length > 0) {
    tempItems = tempItems.filter((knowledgeBaseItem) => {
      return activeTagFilters.value.filter((tag) => {
        return knowledgeBaseItem.tags.indexOf(tag) !== -1;
      }).length;
    })
  }

  return tempItems;
});

const filteredTags = computed(() => {
  let tempItems = props.knowledgeBaseTags;
  if (search.value) {
    tempItems = tempItems.filter((item) => {
      return item.toLowerCase().includes(search.value.toLowerCase());
    });
  }
  return tempItems;
});

function addToTagFilter(tag) {
  let index
  index = activeTagFilters.value.indexOf(tag);
  if (index > -1) {
    if (index === 0) {
      activeTagFilters.value.shift();
    } else {
      activeTagFilters.value.splice(index, 1)
    }
  } else {
    activeTagFilters.value.push(tag)
  }
  tagsDropdownIsOpen.value = false;
}

const currentPage = ref(1);
const rowsPerPage = ref(12);

const {knowledgeBaseItems, numberOfPages} = useKnowledgeBaseItemsApi(
  filteredKnowledgeBaseItems,
  currentPage,
  rowsPerPage
);

function closeCategoriesDropdown() {
  categoriesDropdownIsOpen.value = false;
}

function closeTagsDropdown() {
  tagsDropdownIsOpen.value = false;
}

function openTagsDropdown() {
  if (tagsDropdownIsOpen.value) {
    return;
  }
  tagsDropdownIsOpen.value = true;
}

</script>

<template>
  <div class="flex flex-col gap-8 mt-8 w-full md:gap-12 md:mt-12">
    <form class="flex flex-wrap lg:items-center lg:justify-center gap-4 md:gap-x-8" v-if="knowledgeBaseCategories.length > 0 && knowledgeBaseTags.length > 0">
      <div class="w-full font-heading sm:text-lg lg:w-auto lg:text-xl">Filter op</div>
      <div class="flex flex-col items-center gap-3 w-full sm:flex-row sm:w-auto" v-if="knowledgeBaseTags.length > 0">
        <span class="hidden sm:block">Onderwerpen </span>
        <div class="relative w-full max-w-full sm:w-72" v-click-outside="closeTagsDropdown">
          <input
            class="w-full max-w-full h-[42px] py-1.5 px-3 border border-gray-300 outline-none text-inherit text-sm focus:border-transparent focus:ring-2 focus:outline-none focus:ring-gray-200"
            :class="{ 'ring-2 ring-gray-200': tagsDropdownIsOpen }"
            type="search"
            placeholder="Zoek op onderwerp..."
            v-model="search"
            ref="searchInput"
            @keydown.esc="close"
            @keydown.up="highlightPrev"
            @keydown.down="highlightNext"
            @keydown.enter.prevent="selectHighlighted"
            @keydown.tab.prevent
            @focusin="openTagsDropdown"
          />
          <icon-magnifying-glass v-show="!tagsDropdownIsOpen"
                                 class="absolute z-10 top-3 right-2 w-4 h-4 text-gray-600 pointer-events-none"/>
          <icon-close v-show="tagsDropdownIsOpen" @click.prevent="tagsDropdownIsOpen = false"
                      class="absolute z-10 top-3 right-2 w-4 h-4 text-secondary cursor-pointer"/>
          <div ref="dropdown" v-show="tagsDropdownIsOpen"
               class="absolute z-10 top-[42px] left-0 mt-0.5 w-full bg-white ring-2 ring-gray-200">
            <ul
              ref="knowledgeBaseItems"
              v-show="knowledgeBaseTags.length > 0"
            >
              <li
                class="px-4 py-1 font-body font-normal text-sm transition-colors cursor-pointer hover:bg-gray-50"
                v-for="(option, i) in filteredTags"
                :key="option"
                v-show="activeTagFilters.indexOf(option) == -1"
                @click.prevent="addToTagFilter(option); currentPage = 1"

              >
                {{ option }}
              </li>
            </ul>
            <div v-show="activeTagFilters.length === knowledgeBaseTags.length && filteredTags.length !== 0"
                 class="px-4 py-1 font-body font-normal text-sm text-sm italic">
              Geen resultaten gevonden
            </div>
            <div v-show="filteredTags.length === 0" class="px-4 py-1 text-sm italic">
              Geen resultaten gevonden voor "{{ search }}"
            </div>
          </div>
        </div>
        <div class="flex flex-wrap items-center justify-center gap-3">
          <button @click.prevent="addToTagFilter(tag)"
                  v-for="tag in activeTagFilters"
                  :class="activeTagFilters.indexOf(tag) !== -1 ? 'active' : ''"
                  class="flex items-center gap-2 bg-white text-black text-sm py-2.5 px-4 border border-gray-300 ring-0 hover:bg-gray-100"
          >
            {{ tag }}
            <icon-close class="w-4 h-4 text-secondary"/>
          </button>
        </div>
      </div>
    </form>


    <div class="grid gap-4 sm:grid-cols-2 md:gap-8 xl:grid-cols-3" v-if="knowledgeBaseItems && knowledgeBaseItems.length > 0">
      <a v-for="knowledgeBaseItem in knowledgeBaseItems"
         class="group relative flex flex-col max-w-full overflow-hidden text-black bg-quaternary ring-0 transition-shadow"
         :href="knowledgeBaseItem.url" :aria-label="knowledgeBaseItem.title">
        <span class="aspect-[100/67] overflow-hidden bg-gray-100" v-html="knowledgeBaseItem.image" />
        <span class="relative flex flex-col grow gap-3 pt-4 px-6 pb-6 bg-quaternary sm:py-9 sm:px-[52px]">
          <span class="absolute top-0 left-0 -translate-y-full flex flex-wrap gap-2 px-[27px] py-[21px] p-8" v-if="knowledgeBaseItem.categories.length > 0">
            <span v-for="category in knowledgeBaseItem.categories"
                  class="inline-flex py-[5px] px-3 bg-black/60 font-heading text-sm text-white font-bold"
            >
                {{ category }}
            </span>
          </span>
          <h2 class="text-2xl text-gray-900 font-semibold group-hover:text-secondary" v-text="knowledgeBaseItem.title"/>

          <span class="mb-2 text-gray-700 line-clamp-3" v-html="knowledgeBaseItem.content"/>
          <button class="button button--sm button--hollow self-start mt-auto">
            Bekijk artikel
          </button>
        </span>
      </a>
    </div>
    <div class="flex flex-col items-center content" v-else>
      <p><em>Er zijn geen resultaten gevonden.</em></p>
    </div>
    <div class="mt-12" v-show="numberOfPages > 1">
      <pagination-component
        class="pagination-component"
        v-model="currentPage"
        :numberOfPages="numberOfPages"
      />
    </div>
  </div>
</template>

<style scoped>

</style>