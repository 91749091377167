<template>
  <div class="pagination-container" aria-label="row pagination">
    <ul v-if="numberOfPages >= 1" class="pagination">
      <li
        class="page-item page-item--previous"
        aria-label="go to previous page"
        @click="previous()"
        :class="{
          'disabled': currentPage === 1,
        }"
      >
          <div class="page-link">
              <IconArrowRight class="w-4 h-4 rotate-180 transform"/>
              <span>Vorige</span>
          </div>
      </li>
      <li
        v-for="index in numberOfPages"
        :key="index"
        :aria-label="'go to page ' + index"
        class="page-item"
        @click="setCurrentPage(index)"
      >
        <div
          class="page-link"
          :class="{
            'active-page': currentPage === index,
          }"
        >
          {{ index }}
        </div>
      </li>
      <li
        class="page-item page-item--next"
        :class="{
          'disabled': currentPage === numberOfPages || !numberOfPages,
        }"
        aria-label="go to next page"
        @click="next()"
      >
          <div class="page-link">
              <span>Volgende</span>
              <IconArrowRight class="w-4 h-4"/>
          </div>
      </li>
    </ul>
  </div>
</template>

<script lang="ts" setup>
import {toRefs} from "vue";
import IconArrowRight from "@/scripts/Vue/Icons/IconArrowRight.vue";

const props = defineProps({
  numberOfPages: {
    required: true,
  },
  modelValue: {
    required: true,
    type: Number,
  },
});

const {numberOfPages, modelValue: currentPage} = toRefs(props);

const emit = defineEmits(["update:modelValue"]);

const setCurrentPage = (number: Number) => {
  emit("update:modelValue", number);
};

const previous = () => {
  if (currentPage.value === 1) return;
  emit("update:modelValue", currentPage.value - 1);
};

const next = () => {
  if (currentPage.value >= numberOfPages.value) return;
  emit("update:modelValue", currentPage.value + 1);
};
</script>