window.registerForm = function () {
    return {
        // Nav
        isPast: false,

        hideRegistration() {
            const stickyElm = document.querySelector('#registration');

            let observer = new IntersectionObserver(([e]) => {
                    if (e.intersectionRatio < 1) {
                        this.isPast = true;
                        stickyElm.classList.remove('max-lg:opacity-100');
                    } else {
                        this.isPast = false;
                    }
                },
                {rootMargin: '-97px 0px 0px 0px', threshold: [1]}
            );

            observer.observe(stickyElm);
        },

        toggleRegistration() {
            const toggleElm = document.querySelector('#registration');
            const toggleElmChild = document.querySelector('#registration > div');

            if (toggleElm.classList.contains('max-lg:opacity-0')) {
                toggleElm.classList.remove('max-lg:opacity-0');
                toggleElm.classList.add('max-lg:opacity-100');
                toggleElm.classList.remove('max-lg:shadow-none');
                toggleElm.classList.add('max-lg:shadow-md');
                toggleElmChild.classList.remove('max-lg:translate-x-full');
                toggleElmChild.classList.add('max-lg:translate-x-0');
            } else if (toggleElm.classList.contains('max-lg:opacity-100')) {
                toggleElm.classList.add('max-lg:opacity-0');
                toggleElm.classList.remove('max-lg:opacity-100');
                toggleElm.classList.add('max-lg:shadow-none');
                toggleElm.classList.remove('max-lg:shadow-md');
                toggleElmChild.classList.add('max-lg:translate-x-full');
                toggleElmChild.classList.remove('max-lg:translate-x-0');
            }
        }
    }
}
