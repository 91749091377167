<template>
    <template v-if="curVar && curVar.stock > 0">
        <a
            class="button button--primary text-base ring-0"
            :class="smallButton ? 'button--xs ml-auto' : 'button--xl' "
            @click.prevent="addToCartWithModal(curVar, product)"
        >
            Inschrijven
        </a>

        <!-- add to cart modal -->
        <!-- Todo: On the product detail page this component is being loaded twice, which causes a double modal -->
        <add-to-cart-modal></add-to-cart-modal>
    </template>
<!-- aangepast ivm https://wijzijnweb.atlassian.net/browse/IPCGROEN-435?focusedCommentId=88745 -->
<!--    <template v-else-if="curVar && curVar.executionStartDate && curVar.stock <= 0">-->
<!--        <span>-->
<!--            Inschrijven niet meer mogelijk.-->
<!--        </span>-->
<!--    </template>-->
</template>

<script setup lang="ts">
import {useCartStore} from "../../Stores/cartStore";
import {ShoppingApi} from "../../Composables/ShoppingApi";
import AddToCartModal from "./AddToCartModal.vue";
import {defineProps, onMounted} from "vue";
import {VariantInterface} from "../../Interfaces/VariantInterface";
import {ProductInterface} from "../../Interfaces/ProductInterface";

const cartStore = useCartStore();
const {addToCartWithModal, addToCartWithModalByIds} = ShoppingApi();

interface PropsInterface {
    product: ProductInterface,
    variant: VariantInterface,
    smallButton: boolean,
}

const props = withDefaults(defineProps<PropsInterface>(), {
    smallButton: false,
});

const curVar = props.variant
  ?? props.product.defaultVariant;

function openModalIfUrlContainsAddToCart() {
  const url = new URL(window.location.href);
  const method = url.searchParams.get('m');

  if(method === 'add-to-cart') {
    const variantId = parseInt(url.searchParams.get('var'));
    const productId = parseInt(url.searchParams.get('prod'));
    const qty = parseInt(url.searchParams.get('qty'));

    addToCartWithModalByIds(variantId, productId, qty);
    return true;
  }

  return false;
}

onMounted(() => {
  console.log('onMounted')

  openModalIfUrlContainsAddToCart();
});
</script>