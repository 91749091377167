import '/resources/scss/app.scss';
import 'vue-final-modal/style.css'
import Alpine from 'alpinejs';
import {createVfm} from 'vue-final-modal'
import collapse from '@alpinejs/collapse';
import {createPinia} from 'pinia';
import collect from 'collect.js';
import { createApp } from 'vue/dist/vue.esm-bundler';
import KnowledgeBaseOverview from './Vue/Pages/KnowledgeBaseOverview.vue';
import SelectionAidComponent from "./Vue/Components/SelectionAidComponent.vue";
import RegisterButton from "./../../plugins/craft-shop-plugin/src/resources/js/Vue/Components/Modals/RegisterButton.vue";

const clickOutside = {
    beforeMount: (el, binding) => {
        el.clickOutsideEvent = event => {
            if (!(el == event.target || el.contains(event.target))) {
                binding.value();
            }
        };
        document.addEventListener("click", el.clickOutsideEvent);
    },
    unmounted: el => {
        document.removeEventListener("click", el.clickOutsideEvent);
    },
};

import './modules/axios';

window.pinia = createPinia()
window.vfm = createVfm()

window.collect = collect;

window.Alpine = Alpine;
Alpine.plugin(collapse);
Alpine.start();

import './components/tabs.js';
import './modules/addresses.js';
import './modules/header.js';
import './modules/registerForm.js';
import './modules/homepage.js';
import './modules/notifications.js';
import './modules/sliders.js';
import './modules/turnstile';

import fancyboxComponent from "./modules/fancybox";

// Init sliders
window.onload = (event) => {
    window.sliders();
    window.galleryThumbSliders();
}

const components = [{
    class: fancyboxComponent, selector: "[data-fancybox]"
}];

// Check if class exist and run component
components.forEach((component) => {
    if (document.querySelector(component.selector) !== null) {
        document
            .querySelectorAll(component.selector)
            .forEach((element) => new component.class(element, component.options));
    }
});

const vueSelectionAidContainer = document.getElementById('vue-selection-aid-container')
if (vueSelectionAidContainer) {
    const app = createApp({});
    app.directive("click-outside", clickOutside);
    app.component('selection-aid-component', SelectionAidComponent);
    app.mount('#vue-selection-aid-container');
}

const vueKnowledgeBaseContainer = document.getElementById('vue-knowledge-base-container')
if (vueKnowledgeBaseContainer) {
    const app = createApp({});
    app.directive("click-outside", clickOutside);
    app.component('knowledge-base-overview', KnowledgeBaseOverview);
    app.mount('#vue-knowledge-base-container');
}

const vueRegisterButtonContainers = document.querySelectorAll('.vue-register-button-container')
vueRegisterButtonContainers.forEach((container) => {
    const app = createApp({});
    app.component('register-button', RegisterButton);
    app.provide('$axios', window.axios);
    app.provide('$collect', window.collect);
    app.use(window.pinia);
    app.mount(container);
});