import { sliderVideos } from "./sliderVideos";
import Swiper, {Navigation, Pagination, Keyboard, Autoplay, EffectFade} from 'swiper';
Swiper.use([Navigation, Pagination, Keyboard, Autoplay, EffectFade]);

window.sliders = function() {
    let sliderElements = document.querySelectorAll('[data-slider]');
    let sliders = {};

    if (!sliderElements.length) {
        return;
    }

    sliderElements.forEach(slider => {
        let opts = JSON.parse(slider.dataset.slider);
        const slides = slider.querySelectorAll('.swiper-slide')

        // Enable Swiper if only one slide is available
        if (slides.length === 1) {
            opts = { enabled: false }
        } else {
            Object.assign(opts, {
                on: {
                    ...sliderVideos
                }
            })
        }

        sliders[slider.getAttribute('id')] = new Swiper(slider, opts);

        const ro = new ResizeObserver(entries => {
            for (let entry of entries) {
                sliders[slider.getAttribute('id')].updateSize();
            }
        });

        ro.observe(slider);
    });
};

window.galleryThumbSliders = function () {
    let thumbSliderElements = document.getElementsByClassName('js-gallery-thumbs-slider');
    let thumbsSlider = null;
    let options = {};

    if (thumbSliderElements.length) {
        thumbsSlider = new Swiper('.js-gallery-thumbs-slider', {
            spaceBetween: 14,
            slidesPerView: 3,
            freeMode: true,
            watchSlidesVisibility: true,
            watchSlidesProgress: true,
        });

        options = {
            thumbs: {
                swiper: thumbsSlider,
            }
        }
    }

    const gallerySlider = new Swiper('.js-gallery-slider', {
        effect: 'fade',
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
        },
        ...options
    });
};