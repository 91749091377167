import axios from 'axios';
import {useLoading} from "vue-loading-overlay";

const $loading = useLoading({
    // Pass props by their camelCased names
    isFullPage: true,
    canCancel: false, // default false
    color: '#000000',
    loader: 'spinner',
    width: 120,
    height: 120,
    backgroundColor: '#ffffff',
    opacity: 0.5,
    zIndex: 999,
});

window.axios = axios;
window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
window.axios.defaults.headers.common['X-CSRF-Token'] = window.CSRF_TOKEN;

let vueOverlayLoader = null;
window.axios.interceptors.request.use(
    (config) => {
        // show loader, only if loader is not already shown;
        if (vueOverlayLoader === null && config.loader !== undefined && config.loader) {
            vueOverlayLoader = $loading.show();
        }
        return config;
    },
    (error) => {
        hideLoader();
        return Promise.reject(error);
    }
);

// shared method
function hideLoader() {
    vueOverlayLoader && vueOverlayLoader.hide();
    vueOverlayLoader = null;
}

// Intercept all responses
window.axios.interceptors.response.use(
    (response) => {
        // hide loader
        hideLoader();

        return response;
    },
    (error) => {
        // hide loader
        hideLoader();
        // check if CSRF error
        if(error.code === 'ERR_BAD_REQUEST' && error.response.data.exception === 'yii\\web\\BadRequestHttpException') {
            // log error, browser, CSRF and other details
            window.axios.post(
                '/actions/base-module/csrf/log-csrf-error',
                {
                    error: error,
                    headers: error.config.headers,
                    currentUrl: window.location.href,
                    request: {
                        method: error.config.method,
                        url: error.config.url,
                        data: error.config.data,
                        response: error.request.response,
                        response_url: error.request.responseURL
                    },
                    csrfToken: {
                        window: window.CSRF_TOKEN,
                        axios: window.axios.defaults.headers.common['X-CSRF-Token'] ? window.axios.defaults.headers.common : ''
                    }
                }
            )
            // get new CSRF token
            window.axios
                .get('/actions/users/session-info')
                .then(response => {
                    // update CSRF token
                    window.axios.defaults.headers.common['X-CSRF-Token'] = response.data.csrfTokenValue;
                });
            }
        return Promise.reject(error);
    }
);