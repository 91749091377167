<template>
    <div class="flex flex-col items-center gap-12 w-full">
        <!-- Steps -->
        <div class="flex max-sm:flex-col gap-x-16 lg:gap-x-28 max-sm:gap-y-3 overflow-hidden">

            <!-- Step tabs -->
            <template v-for="(subject, index) in [data.url, ...data.collection]">
                <div class="flex items-center max-sm:gap-x-4 gap-y-4 group sm:flex-col">
                    <div class="max-sm:order-2 text-lg font-heading capitalize">
                        {{ subject.label }}
                    </div>
                    <div
                      class="relative flex items-center justify-center h-6 w-6 rounded-full"
                      :class="index >= currentStep + 1 ? 'bg-stone-100' : 'bg-secondary'"
                    >
                        <icon-check class="w-2.5 min-w-[10px]"
                                    :class="index >= currentStep ? 'hidden' : 'text-white'"
                        />
                        <div
                          class="absolute top-1/2 left-1/2 sm:left-0 -z-10 sm:w-screen max-sm:-translate-x-1/2 sm:-translate-y-1/2 group-last:bg-white max-sm:w-1.5 h-screen sm:h-1.5"
                          :class="index < currentStep ? 'bg-secondary' : 'bg-stone-100'"
                        ></div>
                    </div>
                </div>
            </template>
        </div>

        <template v-for="(subject, index) in [data.url, ...data.collection]">
            <div v-show="index === currentStep" class="flex flex-col gap-12">
                <h3 class="heading-2 text-center">
                    {{ subject.title }}
                </h3>
                <div :class="
                    subject.collection.length > 3
                        ? 'grid max-w-4xl grid-cols-1 gap-x-7 gap-y-2 sm:mx-auto sm:grid-cols-2 sm:gap-y-4 lg:grid-cols-3 xl:grid-cols-4'
                        : 'flex justify-center flex-wrap gap-8'
                ">
                  <template v-if="currentStep === 0" v-for="radio in subject.collection">
                    <label class="flex text-base font-normal leading-5 text-black font-body">
                      <input type="radio" class="mt-1 mr-5 rounded-none border-gray-300 outline-none"
                             :value="subject.label + ',' + radio.id"
                             v-model="checkedRadio">
                      {{ radio.title }}
                    </label>
                  </template>
                    <template v-if="currentStep > 0" v-for="checkbox in subject.collection">
                        <label class="flex text-base font-normal leading-5 text-black font-body">
                            <input type="checkbox" class="mt-1 mr-5 rounded-none border-gray-300 outline-none"
                                   :value="subject.label + ',' + checkbox.slug"
                                   v-model="checkedCheckboxes">
                            {{ checkbox.title }}
                        </label>
                    </template>
                </div>

                <div class="flex flex-col gap-y-4 items-center">
                    <div class="flex gap-x-4">
                        <button class="button button--primary"
                                :class="{'opacity-50 pointer-events-none': currentStep === 0}"
                                @click.prevent="previousStep"
                        >
                            Vorige stap
                        </button>
                        <button class="button button--primary"
                                :class="{
                                    'hidden': currentStep === data.collection.length,
                                    'opacity-50 pointer-events-none': checkedRadio === ''
                                }"
                                @click.prevent="nextStep"
                        >
                            Volgende stap
                        </button>
                        <button class="button button--primary"
                                :class="currentStep === data.collection.length ? 'inline-flex' : 'hidden'"
                                @click.prevent="redirectToFilteredProducts"
                        >
                            Bekijk aanbod
                        </button>
                    </div>
                    <button class="inline-flex text-gray-700 hover:text-secondary"
                            :class="{'hidden': currentStep === data.collection.length || currentStep === 0}"
                            @click.prevent="nextStep">
                        Deze stap overslaan
                    </button>
                </div>
            </div>
        </template>
    </div>
</template>

<script setup>
import {ref} from "vue";
import IconCheck from "../Icons/IconCheck.vue";

const currentStep = ref(0);

let props = defineProps({
    data: String
});

const data = ref(JSON.parse(props.data));
const checkedCheckboxes = ref([]);
const checkedRadio = ref('');
let finalParameters = ref({});
let finalUrl = ref('');

const nextStep = () => {
  currentStep.value++;
  if(window.dataLayer){
    window.dataLayer.push(buildStatisticsForGtm());
  }
  console.log(buildStatisticsForGtm());
}

const previousStep = () => {
  currentStep.value--;
  if(window.dataLayer){
    window.dataLayer.push(buildStatisticsForGtm());
  }
}

const buildStatisticsForGtm = () => {
  buildParameters(checkedRadio.value.split(','));
  checkedCheckboxes.value.forEach(value => buildParameters(value.split(',')));

  const event_value = Object.fromEntries(Object.entries(finalParameters.value));

  return {
    event: 'Keuzehulp',
    event_label: `Stap ${currentStep.value}`,
    event_value
  };
}

const redirectToFilteredProducts = () => {
    finalUrl = document.location.origin;

    buildParameters( checkedRadio.value.split(',') );

    for (const [_, value] of Object.entries(checkedCheckboxes.value)) {
        buildParameters( value.split(',') );
    }

    let index = 0;

    for (const [key, value] of Object.entries(finalParameters.value)) {
        finalUrl += (
            index === 0
                ? `/${
                  data.value.url
                      .collection.find(item => item.id === parseInt(value[0]))
                      .uri
                }`
                : `${index > 1 ? '&' : '?'}${key}=${value.join(',')}`
        );
        index++;
    }
    currentStep.value++;
    if(window.dataLayer){
      window.dataLayer.push(buildStatisticsForGtm());
    }
    window.location.href = finalUrl;
     // window.open(finalUrl);
}

const buildParameters = ([ category, value ]) => {
    if (category in finalParameters.value) {
        if (!finalParameters.value[category].includes(value))
            finalParameters.value[category].push(value);
    } else {
        finalParameters.value[category] = [value];
    }
}
</script>

<style scoped>
  input[type='radio']:checked {
    background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='white' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.207 4.793a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0l-2-2a1 1 0 011.414-1.414L6.5 9.086l4.293-4.293a1 1 0 011.414 0z'/%3e%3c/svg%3e");
  }
</style>