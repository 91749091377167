window.tabs = function () {
    return {
        selectedId: null,
        isMobile: window.innerWidth < 1024,
        dropdownIsOpen: false,

        init() {
            let tabFromUrlHash = window.location.hash.substring(1)

            if(tabFromUrlHash){
                this.$nextTick(() => this.select(tabFromUrlHash));
            } else {
                this.$nextTick(() => this.select('general'));
            }

            window.addEventListener(
                "hashchange",
                () => {
                    let tabFromUrlHash = window.location.hash.substring(1)
                    if(tabFromUrlHash){
                        this.select(tabFromUrlHash)
                    }
                },
                false,
            );
        },
        select(id) {
            this.selectedId = id;
            window.location.hash = id;
        },
        isSelected(id) {
            return this.selectedId === id
        },
        whichChild(el, parent) {
            return Array.from(parent.children).indexOf(el) + 1;
        },
        getSelectedName() {
            let tabName = 'Maak een keuze';
            const selectedTab = document.getElementById(this.selectedId);

            if (selectedTab) tabName = selectedTab.getAttribute('data-tab-name');

            return tabName;
        }
    }
}
