import {usePagination} from "../Components/useClientSidePagination";
import {ComputedRef, Ref} from "vue";

export function useKnowledgeBaseItemsApi(
    knowledgeBaseItems: ComputedRef<[]>,
    currentPage: Ref<number>,
    rowsPerPage?: Ref<number>
) {

    const {paginatedArray, numberOfPages} = usePagination({
        rowsPerPage,
        arrayToPaginate: knowledgeBaseItems,
        currentPage
    });
    return {
        knowledgeBaseItems: paginatedArray,
        numberOfPages
    };
}