window.header = function () {
    return {
        // Nav
        isMobile: false,
        showMobileMenu: false,
        subMenuActive: null,
        subSubMenuActive: null,
        showCartMenu: false,
        showSearch: 'aanbod' === window.location.pathname.substring(1).split('/').shift(),
        searchValue: '',
        showSearchResults: false,

        init() {
            this.getScreenSize()
            window.addEventListener('resize', () => {
                this.getScreenSize()
            })
        },

        toggleMenu() {
            this.showMobileMenu = !this.showMobileMenu

            const $body = document.querySelector('body')

            if (this.showMobileMenu) {
                $body.classList.add('no-scroll')
            } else {
                $body.classList.remove('no-scroll')
            }
        },

        toggleSearch: function () {
            this.showSearch = !this.showSearch

            if (this.showSearch) {
                this.$nextTick(() => this.$refs.search.focus())
            } else {
                this.resetSearch()
                this.$nextTick(() => this.$refs.search.blur())
            }
        },

        getScreenSize() {
            this.isMobile = window.innerWidth < 1024
        },

        setActiveSubMenu(id) {
            this.subMenuActive === id
                ? this.subMenuActive = null
                : this.subMenuActive = id
        },

        setActiveSubSubMenu(id) {
            this.subSubMenuActive === id
                ? this.subSubMenuActive = null
                : this.subSubMenuActive = id
        },

        openSearch() {
            this.searchValue.length > 0
                ? this.showSearchResults = true
                : this.showSearchResults = false
        },

        resetSearch() {
            this.searchValue = ''
            this.showSearchResults = false
        }

    }
}
